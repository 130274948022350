var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"product"}},[_c('div',{staticClass:"crumbs"},[_c('router-link',{attrs:{"to":"/control/companyb"}},[_vm._v("首页")]),_c('label',[_vm._v(">")]),_c('a',[_vm._v("产品管理")])],1),_c('div',{staticClass:"group"},[_c('div',{staticClass:"search-input-group"},[_c('el-input',{attrs:{"type":"text","placeholder":"根据产品名称搜索"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}}),_c('p',{on:{"click":function($event){return _vm.getData()}}},[_vm._v("搜索")])],1),_c('div',{staticClass:"search-filter-group",on:{"click":function($event){return _vm.$router.push('/control/product/add')}}},[_vm._v(" 新增产品备案 ")])]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"产品","width":"210"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"table-merge"},[_c('router-link',{staticClass:"table-merge--img",attrs:{"to":{
              path: '/control/product/info',
              query: { id: scope.row.id },
            }}},[_c('el-image',{attrs:{"src":scope.row.poster,"fit":"cover"}})],1),_c('router-link',{staticClass:"table-merge--title",attrs:{"to":{
              path: '/control/product/info',
              query: { id: scope.row.id },
            }}},[_vm._v(_vm._s(scope.row.name))])],1)]}}])}),_c('el-table-column',{attrs:{"label":"价格"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(((scope.row.price) + " 万元/" + (scope.row.unit || "套")))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"salesVolume","label":"年销售额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("currency")(scope.row.salesVolume))+" 万元 ")]}}])}),_c('el-table-column',{attrs:{"label":"审批状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("status1")(scope.row.status))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"上架状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("status2")(scope.row.status))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"推广状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("popStatus")(scope.row.popStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.status === 0 ||
            scope.row.status === 2 ||
            scope.row.status === 4
          )?[_c('i',{on:{"click":function($event){return _vm.edit(scope.row.id)}}},[_vm._v("编辑")]),_c('i',{on:{"click":function($event){return _vm.del(scope.row.id)}}},[_vm._v("删除")])]:[_c('i',{staticClass:"not"},[_vm._v("编辑")]),_c('i',{staticClass:"not"},[_vm._v("删除")])]]}}])})],1),_c('div',{staticClass:"page"},[(_vm.page.pages > 1)?_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.page.pageSize,"total":_vm.page.total,"current-page":_vm.page.pageNum},on:{"current-change":_vm.getData,"update:currentPage":function($event){return _vm.$set(_vm.page, "pageNum", $event)},"update:current-page":function($event){return _vm.$set(_vm.page, "pageNum", $event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }